.nav-items-wrapper {
  @extend .d-flex;
  height: 76px;
  font-size: $font-size-base-sm;
  .dropdown-nav-header {
    @extend .d-flex, .align-items-center, .position-relative;
    &:hover {
      cursor: pointer;
      .dropdown-content {
        visibility: visible;
      }
      .arrow-down {
        transform: scale(-1);
        color: $gray-500;
      }
    }
  }
  .dropdown-content {
    position: absolute;
    min-width: 160px;
    z-index: 1;
    visibility: hidden;
    .menu-tab {
      width: 916px;
      max-height: 356px;
      background: #fff;
      box-sizing: initial;
      left: 0;
      top: 25px;
      padding: 24px;
      position: absolute;
      z-index: 1000;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .tab-header {
        align-items: center;
        margin: 0;
        .btn-outline-primary {
          border-color: $light-300 !important;
        }
      }
      ul {
        column-count: 4;
        padding-left: unset;
        li {
          list-style: none;
          margin-bottom: 0.5em;
          width: 100%;
        }
      }
      .list-group-item {
        border: none;
        padding-left: 0;
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
  .nav-link-header {
    @extend .d-flex, .align-items-center;
    color: $gray-700;
    &:hover {
      color: $gray-700;
    }
  }
}
