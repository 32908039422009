footer {
  border-top: 1px solid $light-300;
}

.language-btn-container {
  @extend .d-flex;
}

.footer-logo {
  filter: grayscale(100%);
}
.mobile-footer-logo {
  display: none;
}
.footer-desc {
  @extend .text-gray-500;
  font-size: $font-size-base-xs;
}
.list-unstyled {
  list-style: none;
  a {
    font-size: $font-size-base-sm;
    color: $primary-700;
  }
}
.social-container {
  @extend .d-flex;
  @include flex();

  gap: 5px;
  .pgn__icon {
    max-width: 24px;
    max-height: 24px;
  }
  .social-icon-footer {
    cursor: pointer;
    color: $light-500;
    @include flex();
  }
}
.footer-copy-right-container {
  background-color: $light-300;
  .footer-copy-right {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.footer-wrapper {
  display: grid;
  grid-template-columns: repeat(26, 1fr);
  .footer-desc-wrapper {
    grid-column: 1/10;
    grid-row: 1/2;
    p {
      font-size: $font-size-base-sm;
    }
  }
  .footer-col1-wrapper {
    grid-column: 13/17;
    grid-row: 1/2;
  }
  .footer-col2-wrapper {
    grid-column: 17/21;
    grid-row: 1/2;
  }
  .footer-col3-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-column: 22/27;
    grid-row: 1/2;
  }
  .lang-dropdown {
    .dropdown-menu {
      width: auto;
      min-width: 128px;
    }
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .footer-desc-wrapper {
    grid-column: 1/27 !important;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $light-300;
  }
  .footer-col1-wrapper {
    grid-column: 1/9 !important;
    grid-row: 2/3 !important;
  }
  .footer-col2-wrapper {
    grid-column: 11/17 !important;
    grid-row: 2/3 !important;
  }
  .footer-col3-wrapper {
    grid-column: 19/27 !important;
    grid-row: 2/3 !important;
    h5 {
      display: none;
    }
    .social-container {
      justify-content: center;
      gap: 24px;
    }
    .dropdown-wrapper {
      width: 100%;
      margin-bottom: 32px !important;
      .btn {
        width: 100%;
      }
    }
  }
  .footer-copy-right {
    .logo-container {
      img {
        object-fit: contain;
      }
    }
  }
  .lang-dropdown {
    .dropdown-menu {
      width: 100%;
    }
  }
}
@media (max-width: 600px) {
  .footer-wrapper {
    display: flex;
    flex-direction: column;
  }
  .footer-desc-wrapper {
    border-bottom: 1px solid $light-300;
    padding-bottom: 24px;
  }
  .footer-col1-wrapper {
    padding-top: 24px;
    border-bottom: 1px solid $light-300;
  }
  .footer-col2-wrapper {
    padding-top: 24px;
    border-bottom: 1px solid $light-300;
  }
  .footer-col3-wrapper {
    padding-top: 24px;
    h5 {
      display: none;
    }
    .dropdown-wrapper {
      width: 100%;
      margin-bottom: 24px !important;
      .btn {
        width: 100%;
      }
    }
    .social-container {
      justify-content: center;
      gap: 16px;
    }
  }
  .footer-copy-right {
    flex-direction: column;
    align-items: unset !important;
    background-color: $light-200;
    .logo-container {
      width: 32px;
      padding: 4px;
      background-color: $light-300;
      border-radius: 4px;
      img {
        object-fit: contain;
      }
    }
    .footer-logo {
      display: none;
    }
    .mobile-footer-logo {
      display: flex;
      width: 100%;
      filter: grayscale(100%);
    }
    .desktop-view-edx {
      display: none;
    }
    .edx-wrapper {
      margin-top: 20px;
    }
  }
}
