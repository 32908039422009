.nav-wrapper {
  @extend .d-flex, .align-items-center;
  font-size: $font-size-base-sm;
  position: relative;
  margin-bottom: 0;
  list-style: none;
  gap: 1.5rem;
  padding: 0;
  .border-bottom {
    display: none;
    border-bottom: 4px solid $brand-500 !important;
    width: 36px;
    position: absolute;
    bottom: 0%;
  }
  a {
    color: $gray-700;
    &:hover {
      color: $gray-700;
    }
  }
  .active {
    color: $black;
    font-weight: bold;
    .border-bottom {
      display: block;
    }
  }
  li {
    cursor: pointer;
    display: flex;
    height: 76px;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .nav-wrapper {
    padding-left: 0;
    border-bottom: 1px solid $light-300;
    justify-content: space-between;
    li {
      height: unset;
      padding: 8px 0;
    }
    a {
      padding: 10px 12px 10px 12px;
      &:last-child {
        display: none;
      }
    }
  }
}
