.search-header-wrapper {
  background: $background-dark-blue;
  padding: 64px 0;
  .search-header {
    line-height: 1;
    width: auto;
    font-size: 3.75rem;
    font-weight: bold;
    color: $white;
  }
  .search-header-withSearch {
    font-size: 2.5rem;
  }
  .search-value {
    line-height: 1;
    font-size: 3.75rem;
    font-weight: bold;
    color: $white;
  }
  .trending-title {
    font-size: $font-size-base-sm;
    color: $light-500;
  }
  .chip-trend {
    background-color: $primary-400 !important;
    .pgn__chip__label {
      color: $white;
      font-size: $font-size-base-xs;
    }
  }
}
.discover-search-field {
  @include custom-search-field(573px);
}
.search-suggestion-wrapper {
  position: absolute;
  z-index: 10;
  width: 718px;
  background-color: $white;
  box-shadow: $box-shadow-custom;
  border-radius: 4px;
  .search-result-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
  .second-title-wrapper {
    line-height: 1.25rem;
    font-size: $font-size-base-xs;
    color: $gray-500;
  }
  .btn-wrapper {
    .span {
      color: $info-500;
      font-size: $font-size-base-sm;
      font-weight: bold;
    }
    border-radius: 4px;
    padding: 0.875rem 1rem;
  }
}

.explore-container {
  padding-bottom: 32px;
  .subjects-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 24px;
    column-gap: 16px;
    .subject-wrapper {
      background-color: $light-200;
      border-radius: 8px;
      padding: 8px;
      .subject-img-wrapper {
        width: 54px;
        height: 54px;
        border-radius: 4px;
        margin-top: 8px;
        img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      span {
        line-height: 24px;
        margin-top: 8px;
        padding: 8px 0;
        text-align: center;
      }
    }
  }
  .btn {
    width: unset;
  }
}
@media (max-width: 1350px) {
  .subjects-courses-wrapper {
    .view-all-courses-btn {
      width: unset;
    }
    .view-all-btn {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .search-header-wrapper {
    padding: 32px 0;
    .search-header {
      font-size: 36px;
      line-height: 40px;
    }
  }
  // .subjects-courses-wrapper {
  //   .view-all-courses-btn {
  //     width: unset;
  //   }
  //   .view-all-btn {
  //     display: none;
  //   }
  // }
}
@media (max-width: 768px) {
  .explore-container {
    .subjects-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media (max-width: 600px) {
  .subjects-courses-wrapper {
    .view-all-courses-btn {
      width: 100%;
    }
  }
  .search-header-wrapper {
    .search-header {
      letter-spacing: -0.05em;
    }
  }
}
