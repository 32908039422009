//invisible header in search modal in responsive
// This code is commented out because it removed the heading part
// Might be used in other places, later
// .pgn__modal-header {
//   display: none;
// }
// .pgn__modal-close-container {
//   display: none;
// }
//remove scroll-x in modal
.pgn__modal-body {
  // padding: unset;
  // overflow-x: hidden;
  &::after {
    content: unset;
  }
}
.search-modal {

  .pgn__modal-header {
    display: none;
  }
  
  .pgn__modal-close-container{
    display: none;
  }

  h4 {
    color: $gray-500;
    opacity: 0.5;
  }
  .search-wrapper {
    padding: 10px 24px;
    border-top: 1px solid $light-400;
    border-bottom: 1px solid $light-400;
  }
  .has-focus {
    box-shadow: unset;
  }
  .pgn__modal-body {
    padding: unset;
  }
  .pgn__searchfield {
    width: 100%;
    border: unset;
    .form-control {
      &::placeholder {
        font-size: 0.875rem;
      }
      font-size: 1rem;
      color: black;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .pgn__icon {
    color: $gray-500;
  }
  .recent-view-wrapper {
    span {
      color: $gray-500;
      opacity: 0.5;
    }
    .logo-img-wrapper {
      width: 44px;
      height: 44px;
      border-radius: 4px;
      margin-right: 16px;
      border: 1px solid $light-400;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .recent-title {
      color: black;
      font-size: 1rem;
      font-weight: bold;
    }
    .recent-institution {
      color: $gray-500;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .pgn__icon {
      color: $gray-500;
      opacity: 0.5;
    }
  }
  .btn-outline-primary {
    padding: 5px 8px;
    color: $primary-700;
    border: 1px solid $light-300;
    font-size: 0.75rem;
  }
  .search-result-modal-wrapper {
    .suggestion-title {
      color: $gray-700;
      font-weight: 400;
    }
    .second-title-wrapper {
      font-size: 0.875rem;;
      color: $gray-500;
    }
  }
}