.profile-header {
  background-color: $light-200;
  padding: 10px 24px;
  width: 100%;
  .email-title {
    font-size: $font-size-base-sm;
    font-weight: bold;
    margin-right: auto;
    @include line-break(1);
  }
  .profile-btn-wrapper{
    .btn{
        width: 100%;
    }
  }
}
.profile-nav-items{
    .nav-link{
        font-weight: bold;
        padding: 8px 0px;
    }
}