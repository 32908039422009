.partners-container {
  padding-top: 80px;
  padding-bottom: 80px;
  .partners-logo-wrapper {
    gap: 24px;
  }
  .partners-img-wrapper {
    width: 112px;
    height: 112px;
  }
  img {
    width: 100%;
    height: 100%;
    padding: 8px;
    object-fit: contain;
  }
  .partner-logo-skeleton-wrapper {
    display: flex;
    gap: 24px;
    margin-right: 24px;
    span {
      width: 100%;
    }
    .partner-logo-skeleton {
      max-width: 208px;
      height: 90px;
    }
  }
}

@media (max-width: 1024px) {
  .partners-container {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
@media (max-width: 768px) {
  .partners-container {
    .partners-img-wrapper {
      width: 89px;
      height: 48px;
    }
  }
}
