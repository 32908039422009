.skeletonCard-container {
    position: relative;

    .blur-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.70);
        backdrop-filter: blur(2px);
    }

    .loading-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 2;
    }
}