@import 'components/shared/header-component/Header/DesktopHeader/NavHeader/nav-header.scss';
@import 'components/shared/header-component/Header/DesktopHeader/DropdownNavHeader/dropdown-nav-header.scss';
@import 'components/shared/header-component/Header/SearchModal/search-modal.scss';
@import 'components/shared/header-component/Header/MobileHeader/mobile-profile.scss';

.header-wrapper {
  box-shadow: $card-ing-shadow;
  -webkit-box-shadow: $card-ing-shadow;
  position: relative;
  z-index: 1;
}

.right-side-wrapper {
  .pgn__searchfield {
    width: 280px;
    margin: 15px 0;
    .btn {
      padding: 9px 9px;
    }
  }
}

.left-side-container {
  @extend .d-flex, .flex-row, .align-items-center;
  display: -webkit-flex;
  padding-left: 36px;
}

.logo-container {
  width: 146px;
  height: 32px;
  img {
    height: 100%;
    object-fit: contain;
    -webkit-object-fit: contain; // safari
  }
}

.sign-in-container {
  @extend .d-flex, .align-items-center;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  max-height: 76px;
  padding: 0 20px;
  background-color: $light-300;
  .avatar-dropdown-wrapper {
    .disabled {
      opacity: 0.5;
    }
    .avatar-wrapper {
      width: 36px;
      height: 36px;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
    .btn-primary {
      background-color: $light-300;
      border-color: $light-300;
      &:hover {
        border-color: $light-300 !important;
      }
    }
    .dropdown-toggle::after {
      content: none;
    }
    .btn {
      padding: 4px;
      height: 24px;
    }
    .pgn__icon {
      margin-left: 0;
      margin-right: 4px;
      color: $primary-500;
      width: 24px;
      height: 24px;

      &:first-of-type{
        width: 40px;
        height: 36px;
      }
    }
  
    .dropdown-menu {
      min-width: 200px;
      border-radius: 4px;
      font-size: 18px;
      line-height: 28px;
    }
    .dropdown-item {
      padding: 0.625rem 1rem;
      color: $gray-700;
      &:hover {
        color: $black;
      }
      &:last-child {
        color: $gray-500;
      }
    }
  }
}
.hidden-top-mobile-header {
  height: 56px;
}
.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  -webkit-transition: top 0.3s;
  box-shadow: $box-shadow-mobile;
  -webkit-box-shadow: $box-shadow-mobile;
  z-index: 11;
}
.mobile-search {
  border: 1px solid $light-300;
  border-radius: 8px;
}
.mobile-bottom-nav-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: $white;
  border-top: 1px solid $light-300;
  font-size: 11px;
  color: $gray-500;
  .active {
    background-color: unset !important;
    color: $brand-500 !important;
  }
  span {
    color: $gray-500;
    line-height: 15px;
  }
}
@media (max-width: 1024px) {
  .right-side-wrapper {
    .pgn__searchfield {
      width: 44px;
      height: 44px;
      justify-content: center;
      border: 1px solid $light-300;
      .form-control {
        padding: unset;
      }
    }
  }
}
