@mixin flex($flexDirection: row, $justifyContent: center, $alignItem: center) {
  display: flex;
  display: -webkit-box;

  flex-direction: $flexDirection;
  -webkit-flex-direction: $flexDirection;

  align-items: $alignItem;
  -webkit-align-items: $alignItem;
  
  justify-content: $justifyContent;
  -webkit-justify-content: $justifyContent;
}

@mixin center-vertically {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin line-break($line-clamp) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin custom-search-field($width) {
  .pgn__searchfield_wrapper {
    border: 1px solid $primary-700;
    border-radius: 4px;
    width: $width;
    background-color: $white;
    &:hover {
      border-color: $primary-700;
    }
    .btn {
      background-color: $white;
      padding: 0 1rem;
    }
    .pgn__icon {
      color: $gray-500;
    }
  }
  .pgn__searchfield__button {
    text-transform: capitalize;
    background-color: $brand !important;
    border-radius: 4px !important;
    flex-shrink: 0;
  }
  .form-control {
    font-size: $font-size-base-sm;
    height: 44px;
  }
}
@mixin custom-breadcrumb($color: $primary-500, $active-color: $gray-500) {
  .pgn__breadcrumb {
    .link-muted {
      color: $color !important;
    }
    .pgn__icon {
      color: $color;
    }
    .active {
      color: $active-color;
    }
  }
}
@mixin infinite-background-container($color) {
  &::before {
    background: $color;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 500%;
    z-index: -1;
  }
  &::after {
    background: $color;
    bottom: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 500%;
    z-index: -1;
  }
}
@mixin card-styles {
  .card-icon {
    @extend .mr-2;
    width: 20px;
    color: $gray-500;
  }
  .pgn__icon {
    height: unset;
    opacity: 0.5;
  }
  .instructor-title {
    text-decoration: none !important;
    color: $black !important;
    &:hover {
      color: $info-500 !important;
    }
  }
  .program-instructors-wrapper {
    max-width: 244px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      &::after {
        content: ', ';
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .program-instructors-wrapper {
      max-width: 194px;
    }
  }
}
