.ad-card-wrapper {
  width: 324px !important;
  height: 325px;
  box-shadow: unset;

  .highlight-wrapper {
    grid-column: 1/2;
    grid-row: 1/2;
    width: 225px;
    .highlightd-ad {
      .highlighted-banner{
        color: $accent-b !important;
      }
        color: $white;
    }
  }
  .img-wrapper {
    grid-column: 2/6;
    grid-row: 1/2;
  }
}
.text-wrapper {
  position: absolute;
  top: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.banner-wrap {
  background-color: $primary-500;
  height: 181px;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
