.hero {
  background: $background-dark-blue;
  white-space: pre-line;
  .display-1 {
    @extend .mb-4;
    color: $white;
    // max-width: 512px;
  }
  .banner-wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .text-container {
    grid-column: 1/7;
    grid-row: 1/2;
  }
  .img-container {
    grid-column: 7/11;
    grid-row: 1/2;
  }
  .hero-image {
    object-fit: contain;
    width: 100%;
    max-height: 318px;
  }
  .hero-search {
    @include custom-search-field(354px);
  }
  .col {
    padding: unset;
  }
  .banner-search-box{
    max-width: 462px;
  }
}

@media (max-width: 600px) {
  .text-container {
    grid-column: 1/11 !important;
    .display-1 {
      font-size: 36px;
      line-height: 40px;
    }
  }
  .img-container {
    display: none;
  }
  .banner-wrapper {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .text-container {
    grid-column: 1/8 !important;
    .display-1 {
      font-size: 36px;
      line-height: 40px;
    }
  }
  .img-container {
    grid-column: 8/11 !important;
  }
  .banner-wrapper {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
