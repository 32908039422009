$progress-bar-lable-color: #002F4A;
$progress-bar-border-color: #707070;
$progress-height: 18px;

.progress-bar-wrapper {
    position: relative;
    width: 280px;
    height: $progress-height;

    .progress-bar-label {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 8px;
        margin-top: -1px;
        color: $progress-bar-lable-color;
        font-size: .875rem;
        font-weight: 400;
    }

    .progress {
        height: $progress-height;
        border-color: $progress-bar-border-color; 
    }
}