.user-card-course {
  border-color: $light-400;
  cursor: pointer;
  .pgn__card {
    display: flex;
  }
  .pgn__card-wrapper-image-cap {
    border: 1px solid $light-300;
    display: flex;
    flex-shrink: 0;
    width: 248px;
    height: 216px;
    max-width: unset;
    min-width: unset;
    .pgn__card-image-cap {
      width: 100% !important;
    }
  }

  .pgn__card-section {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .course-title {
    color: black;
    margin-right: 20px !important;
    @include line-break(1);
  }
  .second-title {
    color: $gray-500;
    font-size: $font-size-base-sm;
  }
  .progress {
    background: $light-200;
    border: 1px solid $light-700;
    max-width: 282px;
    width: 100%;
    .progress-bar {
      background-color: $info-300;
    }
  }
  .org-title {
    color: $gray-700;
  }
  .course-date-title {
    color: $gray-500;
    font-size: $font-size-base-sm;
    padding: 20px 0;
  }
  .icons-wrapper {
    height: 28px;
  }
  .btn {
    font-size: $font-size-base-xl;
  }
  .title-wrapper {
    min-height: 28px;
  }
  //dropdown icon
  .pgn__icon {
    color: $gray-500;
  }
  .dropdown-menu {
    min-width: unset;
    // transform: translate(-60px, 46px) !important;
  }
  .dropdown-item {
    padding: 0.375rem 1rem;
    color: $gray-700;
  }
  .dropdown-icon {
    .btn-icon-primary {
      &:hover {
        background-color: unset;
      }
      &:focus {
        box-shadow: unset;
      }
    }
  }
  .view-btn {
    padding: 8px 12px;
    background-color: $primary-500;
    color: $white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
  }
  .course-info-button {
    border: 1px solid $light-400;
    color: black;
  }
  .bottom-wrapper {
    height: 45.98px;
    margin-top: auto;
  }
  .course-card-btn-wrapper {
    display: flex;
    flex-shrink: 0 !important;
  }
  .share-icon {
    opacity: unset;
  }
}

// .dark-background {
//   background-color: $primary-700;
//   h3 {
//     color: $white;
//   }
//   .org-title {
//     color: $light-300;
//   }
//   .course-date-title {
//     color: $light-500;
//   }
//   .progress {
//     background-color: $primary-700;
//     border-color: $gray-500;
//   }
//   .btn-outline-primary {
//     border-color: $light-400;
//     color: $white;
//   }
//   .btn-primary {
//     background-color: $white;
//     color: $primary-700;
//   }
// }
.info-icon {
  color: $light-700;
  opacity: 0.5;
}
.check-icon {
  color: $brand-500 !important;
}
.check-circle-icon {
  transform: unset !important;
  color: $success-300 !important;
}
.more-vert-wrapper {
  display: none;
  background-color: $white;
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
}
.close-wrapper {
  color: $gray-500;
  border-bottom: 1px solid $light-300;
  .pgn__icon {
    width: 20px;
    height: 20px;
  }
}
.more-modal-items {
  position: absolute !important;
  bottom: 0;
  width: 100%;
}
.complete-wrapper {
  margin-top: 36px;
}
.unenroll-icon-wrapper {
  .pgn__icon {
    color: $danger-500;
  }
}
@media (max-width: 1024px) {
  .user-card-course {
    .course-title {
      @include line-break(1);
      font-size: $font-size-base-xl;
    }
    .org-title {
      font-size: $font-size-base-sm;
    }
    .complete-wrapper {
      flex-direction: column;
      align-items: unset !important;
      gap: 10px;
      margin-top: unset;
    }
    .title-wrapper {
      height: unset;
    }
  }
}
@media (max-width: 768px) {
  .user-card-course {
    .icons-wrapper {
      .share-icon {
        display: none !important;
      }
    }
    .title-wrapper {
      justify-content: space-between;
    }
    .course-title {
      @include line-break(1);
      font-size: $font-size-base-xl;
    }
    .view-course-btn {
      width: fit-content;
      padding: 8px 12px;
      margin-top: 8px;
    }
    .course-date-title {
      padding: 12px 0;
    }
    .pgn__card-section {
      padding: 12px 16px;
    }
    .pgn__card-wrapper-image-cap {
      width: 218px;
      height: 190px;
    }
  }
}

@media (max-width: 600px) {
  .user-card-course {
    .icons-wrapper {
      .dropdown {
        display: none !important;
      }
    }
    .more-vert-wrapper {
      display: unset;
    }
    .pgn__card-wrapper-image-cap {
      width: unset;
      min-height: 216px;
      .pgn__card-image-cap {
        max-height: unset;
      }
    }
    .course-title {
      // max-width: 279px;
      @include line-break(3);
    }
    .course-date-title {
      padding: 16px 0;
    }
    .more-vert-tablet-wrapper {
      display: none;
    }
    .progress {
      width: 100%;
      max-width: unset;
    }
    .view-course-btn {
      width: 100%;
      height: 44px;
      margin-top: 24px;
      a {
        width: 100%;
      }
    }
    .pgn__card-section {
      padding: 16px;
    }
  }
}
