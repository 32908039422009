.facets-wrapper {
  flex-wrap: wrap;
  .facet-btn {
    .btn {
      border-color: $light-300;
      background-color: $white;
      color: $gray-700;
      font-size: $font-size-base-sm;
      font-weight: 500 !important;
      &:active {
        background-color: $white !important;
        border-color: $light-300 !important;
      }
      &::before {
        border: unset;
      }
    }
    .show {
      .btn-outline-primary.dropdown-toggle {
        color: #454545;
        background-color: $white;
        border-color: $light-300;
      }
    }
    .dropdown-toggle::after {
      content: none;
    }
  }
  .dropdown-menu {
    box-shadow: $box-shadow-custom;
  }

  .pgn__form-control-set {
    .pgn__form-radio {
      width: 100%;
      padding: 14px 16px;
      div {
        width: 100%;
        span {
          font-size: $font-size-base-sm;
        }
        .count {
          margin-left: auto;
          font-size: $font-size-base-xs;
          color: $gray-500;
          opacity: 0.5;
        }
      }
    }
  }
  .pgn__form-control-set > * + * {
    margin: 0;
  }
}
.facet-menu {
  .no-result {
    padding: 8px 16px;
    display: flex;
    min-width: max-content;
  }
  .pgn__searchfield {
    min-width: 288px;
    border-radius: 0;
    border: unset;
    border-bottom: 1px solid $light-500;
    .btn {
      border-color: transparent;
      .pgn__icon {
        width: 24px;
        color: $gray-500;
        opacity: 0.75;
      }
    }
  }
  .pgn__form-group {
    margin-bottom: 0;
    .pgn__menu {
      box-shadow: unset;
      .item-wrapper {
        font-size: $font-size-base-xl;
        span {
          color: $gray-500;
          opacity: 0.5;
        }
        &:hover {
          background-color: $light-200;
        }
        .pgn__menu-item {
          min-width: 212px;
          height: 44px;
          margin: 12px 16px;
          padding: 0;
          &:hover {
            background-color: $light-200;
            .pgn__form-checkbox-input {
              border-color: $brand-500;
              box-shadow: unset;
              flex-shrink: 0;
            }
          }
        }
        .pgn__form-label {
          font-size: $font-size-base-xl;
          color: $gray-700;
          .pgn__menu-item-text {
            color: $gray-700 !important;
            opacity: unset;
          }
        }
        .pgn__form-checkbox-input {
          flex-shrink: 0;
          border-color: $gray-500;
          border-radius: 3px;
          width: 18px;
          height: 18px;
          &:focus {
            &::before {
              content: none;
            }
          }
          &:checked {
            background-image: url(../../assets/Form.Checkbox.png);
            border-color: transparent;
          }
          &:checked + div label {
            font-weight: bold;
          }
        }
      }
    }
  }
}
[dir='rtl'] {
  .facets-wrapper {
    .dropdown-menu {
      inset: 0px 0px auto auto !important;
    }
  }
}
.badge-wrapper {
  .badge-btn {
    font-size: $font-size-base-xs;
    height: 28px;
    background: $light-500;
    border: 1px solid $light-500;
    border-radius: 6px;
    color: black;
  }
  .clear-btn {
    height: 28px;
  }
}

.img-noResult-wrapper {
  width: 312px;
  height: 134px;
  img {
    width: 100%;
    height: 100%;
  }
}

.flex-basis-50 {
  flex-basis: 50%;
}