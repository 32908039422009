.not-enrolled-wrapper {
  margin-bottom: 300px;
  .pgn__card-image-cap {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: unset;
    min-width: unset;
  }
  .pgn__card-footer {
    width: unset;
  }
  .pgn__card-section {
    height: 100%;
    display: flex;
  }
  p {
    font-size: $font-size-base-xl;
  }
  .btn-brand {
    width: 216px;
    font-size: $font-size-base-xl;
    background-color: $brand-600;
    flex-shrink: 0;
  }
}
@media (max-width: 768px) {
  .not-enrolled-wrapper {
    .explorer-wrapper {
      flex-direction: column;
      align-items: unset !important;
    }
    .btn-brand {
      margin-top: 30px;
    }
  }
}
@media (max-width: 600px) {
  .not-enrolled-wrapper {
    .explorer-wrapper {
      flex-direction: column;
      align-items: unset !important;
    }
    .btn-brand {
      margin-top: 30px;
      width: 100%;
    }
  }
}
