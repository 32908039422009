.course-info-top-container {
  position: relative;
  @include infinite-background-container($light-200);

  .course-info-top-wrapper {
    position: relative;
    padding-right: calc(320px + 72px);
    background-color: $light-200;

    .icons-skeleton-wrapper {
      display: flex;
      gap: 32px;
      width: 70%;

      span {
        width: 100%;
      }
    }

    h1 {
      line-height: 44px;
    }
  }

  .icons-wrapper {
    gap: 32px;

    .pgn__icon {
      transform: unset;
      opacity: 0.5;
      margin-right: 0.5rem;
    }
  }
}

.course-info-breadcrumb {
  @include custom-breadcrumb($gray-700, $gray-500);
  background-color: $light-200;
}

.course-institution {
  color: $gray-700 !important;
  width: fit-content;

  :hover {
    color: $gray-900 !important;
    text-decoration: underline !important;
  }
}

.sticky-trigger {
  @include infinite-background-container($light-200);

  h3 {
    @include line-break(1);
  }

  display: flex !important;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 9;
  height: 107px;
}

.sticky-nav {
  top: 107px !important;
  z-index: 9;
}

.course-info-side-wrapper {
  @include card-styles;
  position: sticky;
  width: 324px;
  top: 160px;
  margin-top: 68px;
  z-index: 8;
  float: right;

  .skeleton-logo {
    top: 154px;
  }

  .cards-info-wrapper {
    border: unset;
    box-shadow: $card-ing-shadow;

    &:hover {
      box-shadow: $card-ing-shadow;
    }

    .btn-card-info-container {
      width: 100%;

      .enroll-btn {
        width: 100%;
        height: 44px;
        color: $white;
        white-space: nowrap;
      }
    }

    .pgn__card-image-cap {
      border-top-left-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
      height: 204px;
    }

    .pgn__card-logo-cap {
      object-fit: contain;
    }

    .pgn__card-wrapper-image-cap {
      max-height: unset;
    }
  }

  .date-text {
    opacity: 0.5;
  }
}

.nav-container-top-trigger {
  height: 1px;
}

.dropdown-course-nav-container {
  border-bottom: unset !important;

  .active-item {
    .dropdown-menu {
      background-color: unset !important;

      .active {
        background-color: unset !important;
      }
    }
  }

  .show {
    .btn {
      background-color: $light-400 !important;
    }
  }

  .btn {
    background-color: $light-300 !important;
    width: 44px;
    height: 44px;

    .pgn__icon {
      margin: 0;
      width: unset;
    }
  }

  .dropdown-menu {
    transform: translate(-118px, 46px) !important;

    .active {
      background-color: $light-300;
    }
  }
}

.drop-items-wrapper {
  a {
    padding: 6px 16px;
  }
}

.sticky-nav-wrapper {
  position: sticky;
  top: 0;
  font-size: $font-size-base-xl;
  white-space: nowrap;

  ul {
    display: flex;
    margin-bottom: unset;
    list-style: none;
    padding-left: 0;
    gap: 32px;

    li {
      padding: 8px 0;
      color: $gray-500;
      cursor: pointer;
    }
  }

  .active-item {
    border-bottom: 2px solid $brand-500;
    font-weight: 500;
    color: $primary-500;
  }

  .disable-link {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    cursor: default;
  }
}

.sticky-nav-wrapper-infinite-border {
  @include infinite-background-container($light-300);
}

.about-wrapper {
  padding-top: 40px;
  padding-bottom: 48px;
  .btn-tertiary {
    color: $info-500;
    padding: 0;
    &:hover {
      background-color: unset;
    }
    &::before {
      border: unset;
    }
    &:focus {
      box-shadow: unset;
    }
    &:active {
      background-color: $white !important;
    }
  }
  .about-content-wrapper {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px; /* Adjust this value to change the height of the fade effect */
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
    }
  }
}

.what-learn-wrapper {
  background-color: $light-200;
  border-radius: 6px;
  padding: 32px;
}

.requirements-wrapper {
  padding-top: 48px;

  .attention-wrapper {
    @extend .d-flex, .flex-column;
    padding: 24px;
    background-color: $warning-100;
    border-radius: 6px;

    .pgn__icon {
      color: $warning-300;
    }
  }

  .prerequisite-courses-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    gap: 24px;

    .pgn__card-image-cap {
      height: 92px;
    }

    .btn {
      width: 100%;
    }
  }
}

.course-content-wrapper {
  padding-top: 40px;

  .collapsible-btn {
    width: 36px;
    height: 36px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-300;

    .pgn__icon {
      color: $gray-500;
    }

    &:focus {
      box-shadow: unset;
      background-color: unset;
    }

    &:hover {
      background-color: unset;
    }
  }

  .collapsible-btn-open {
    background-color: $light-200 !important;
  }

  .collapsible-open {
    display: none !important;
  }

  .is-open {
    .pgn__icon {
      color: $black;
    }
  }

  .collapsible-wrapper {
    margin-left: 52px;

    .collapsible-icon {
      margin-left: 18px;
    }

    .count-title {
      font-size: $font-size-base-std;
      color: $gray-500;
    }

    .collapsible-card {
      border: 1px solid $light-400;
      border-radius: 4px;

      .collapsible-trigger {
        font-weight: 500;
        padding: 1rem;
      }
      .collapsible-body {
        font-size: $font-size-base-std;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        display: flex;
        .pgn__icon {
          color: $gray-500;
          opacity: 0.5;
        }
      }
      .unit-btn {
        width: fit-content;
        padding: unset;
        &:hover {
          background-color: unset;
        }
        &::before {
          border: unset;
        }
      }
      .remove-pointer {
        cursor: unset;
      }
    }
  }
}

.course-content-container {
  max-width: 1008px;
  padding-right: 36px;
}

.course-info-instructors {
  .instructor-course-img-wrapper {
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    border-radius: 6px;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-300;

    img {
      width: 100%;
      height: inherit;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .instructor-wrapper {
    background-color: $white;
    border: 1px solid $light-300;
    padding: 23px;

    &:hover {
      border: 1px solid $light-400;

      .instructor-title {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }

    .instructor-short-bio {
      max-width: 267px;
    }
  }

  .btn-outline-primary {
    width: 100%;
    height: 60px;
    color: $gray-500;
    border: 1px solid $light-400;
  }
}

.carousel-control-prev {
  color: $gray-500 !important;
  display: flex;
  align-items: unset;
  width: unset;
  margin-top: -60px;
  left: unset;
  right: 52px;

  &:hover {
    opacity: 1;
  }
}

.carousel-control-next {
  color: $gray-500 !important;
  display: flex;
  align-items: unset;
  width: unset;
  margin-top: -60px;

  &:hover {
    opacity: 1;
  }
}

.course-tooltip {
  span {
    &::after {
      content: ', ';
    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}

.transcript-title {
  @include line-break(1);
  max-width: 180px;
}

.mobile-course-info-container {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .course-info-img-wrapper {
    .org-img-wrapper {
      width: 80px;
      height: 40px;

      .org-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .price-wrapper {
    width: 100%;
    background-color: $white;
    z-index: 10;
    position: fixed;
    bottom: 56px;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15),
      0px -1px 4px rgba(0, 0, 0, 0.15);

    .btn {
      height: fit-content;
    }

    .pgn__icon {
      color: $warning-300;
    }
  }

  .course-cover-container {
    position: relative;
    margin-bottom: 46px;

    .partner-img-wrapper {
      width: 100%;
      height: 218px;

      img {
        height: inherit;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .partner-logo-container {
      .partner-logo-wrapper {
        width: 116px;
        height: 66px;
        padding: 0.5rem;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
          0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        background-color: $white;
        position: absolute;
        left: 24px;
        bottom: -16px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .share-icon {
        display: none;
      }
    }
  }
}

.mobile-icons-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  color: black;

  .pgn__icon {
    color: $gray-500;
    opacity: 0.5;
    transform: unset;
    margin-right: 0.5rem;
  }

  .program-instructors-wrapper {
    a {
      color: black !important;
    }
  }
}

@media (max-width: 1024px) {
  .course-info-instructors {
    .instructor-course-img-wrapper {
      width: 60px;
      height: 60px;
      margin-right: 16px;
    }

    .instructor-wrapper {
      padding-bottom: 16px;

      &:hover {
        .arrow-forward-btn {
          display: none;
        }
      }

      span {
        font-size: $font-size-base-xs;
      }

      .instructor-title {
        @include line-break(1);
      }
    }
  }

  .about-wrapper {
    padding: 32px 0;
  }

  .requirements-wrapper {
    padding-top: 32px;
  }

  .course-content-wrapper {
    padding-top: 32px;
  }

  .collapsible-wrapper {
    .subsection-wrapper {
      flex-direction: column;
      gap: 8px;

      .count-title {
        font-size: $font-size-base-sm !important;
      }
    }
  }
}

@media (max-width: 860px) {
  .sticky-nav-wrapper {
    ul {
      gap: 16px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .sticky-nav-wrapper {
    background-color: $light-300;
    top: 56px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    z-index: 8;

    ul {
      overflow-x: auto;
    }
  }

  .course-content-container {
    padding-right: unset;
  }
}

@media (max-width: 600px) {
  .mobile-icons-wrapper {
    grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;

    .program-instructors-wrapper {
      max-width: unset;
    }
  }

  .title-share-wrapper {
    .share-icon {
      display: none;
    }
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;

  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  iframe {
    border-radius: 8px;
  }
}

.course-info-alert {
  .pgn__modal-header {
    display: flex;
  }

  .pgn__icon {
    transform: unset;
  }
}

.bottom-sheet-alert {
  border-radius: 28px 28px 0 0;
  .icon-wrapper {
    color: $success-500 !important;
    h3 {
      color: $success-900 !important;
    }
  }
  .pgn__icon {
    width: 36px;
    height: 36px;
    transform: unset;
  }
  .warning-icon {
    width: unset;
    height: unset;
  }
  .pgn__action-row {
    gap: 8px;
    .btn-tertiary {
      margin-bottom: 0;
    }
  }
}
.success-alert {
  .pgn__modal-title {
    color: $success-900;
  }
}
