.explore-course-wrapper {
  .pgn__card {
    display: flex;
    height: 100%;
    .pgn__card-image-cap {
      height: 92px;
    }
    .pgn__card-section {
      display: flex;
      padding: 1.25rem 1.25rem 0 1.25rem;
    }
  }
}
.cards-wrapper {
  @include card-styles;
  min-width: 272px;
  height: 100%;
  .pgn__card-wrapper-image-cap {
    height: 92px;
    .pgn__card-image-cap {
      height: inherit;
    }
  }
  .pgn__card-logo-cap {
    object-fit: contain;
    background-color: $light-200;
  }
  .pgn__card-footer {
    padding: 1.5rem;
    padding-top: 0;
  }
  .course-title {
    @include line-break(2);
    line-height: 24px;
    text-align: start;
  }
  .institution-title {
    display: block;
    width: fit-content;
    max-width: 274px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn-card-container {
    width: 100%;
    .learn-btn {
      width: 100%;
      height: 44px;
      color: $primary;
      background-color: $white;
      border-color: $light;
    }
  }
  .features-wrapper {
    gap: 8px;
  }
  .price-title {
    color: $gray-500;
    padding: 6px 0;
  }

  &:hover {
    .btn {
      background-color: $primary;
      color: $white;
    }
  }
}

.programs-background {
  background-color: $primary-700;
  &:hover {
    .btn-primary {
      background-color: $primary-400 !important;
      border-color: $primary-400 !important;
    }
  }
  .course-title {
    color: $white;
  }
  .institution-title {
    color: $accent-a;
    max-width: 244px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .course-text {
    color: $white !important;
  }
  .card-icon {
    color: $light-500;
  }
  .price-title {
    color: $light-500;
  }
  .btn-primary {
    background-color: $primary-700 !important;
    color: $white !important;
    &:hover {
      background-color: $primary-400 !important;
      border-color: $primary-400 !important;
    }
  }
}
@media (max-width: 1024px) {
  .btn-card-container {
    display: none;
  }
}
