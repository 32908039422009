$font-size-base-std: 1rem;
$font-size-base-sm: 0.875rem;
$font-size-base-xl: 1.125rem;
$font-size-base-xs: 0.75rem;
$background-dark-blue: linear-gradient(270deg, #131628 0%, #262b54 100%);
$card-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15),
  0px 0px 8px rgba(0, 0, 0, 0.15);
$card-ing-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
  0px 1px 4px rgba(0, 0, 0, 0.15);
$box-shadow-custom: 0px 2px 4px rgba(0, 0, 0, 0.15),
  0px 2px 8px rgba(0, 0, 0, 0.15);
$box-shadow-mobile: 0px 2px 8px 0px #00000026;