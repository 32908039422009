@import '@edx/brand/paragon/fonts.scss';
@import '@edx/brand/paragon/variables.scss';
@import '@edx/paragon/scss/core/core.scss';
@import '@edx/brand/paragon/overrides.scss';

// Variables and mixins must come before other styles
@import './styles/variables';
@import './styles/mixins';

@import 'components/home-page/home/HomePageBanner/home-page-banner.scss';
@import 'components/home-page/home/ExplorerCourses/explorer-courses.scss';
@import 'components/home-page/home/TopPrograms/top-programs.scss';
@import 'components/home-page/home/HomePagePartners/home-page-partners.scss';
@import 'components/home-page/home/PopularSubjectsWrapper/popular-subjects.scss';
@import 'components/shared/shared.scss';
@import 'components/dashboard/OverviewPage/overview.scss';
@import 'components/instructor-page/instructor.scss';
@import 'components/discover-page/discover.scss';
@import 'components/search-page/search.scss';
@import 'components/search-page/Search/mobile-search.scss';
@import 'components/partners-list-page/partners-list.scss';
@import 'components/partner-page/partner.scss';
@import 'components/course-info-page/course-info.scss';
@import 'components/program-info-page/ProgramPage.scss';
@import 'components/static-pages/static-pages.scss';

body {
  overflow-x: hidden;
}
