.subject-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0px, 1fr));
  gap: 24px;
}
.subject-img {
  @extend .mr-2;
  width: 66px;
  height: 66px;
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
}
.subject-wrapper {
  @extend .d-flex, .align-items-center;
  cursor: pointer;
  &:hover {
    .subject-img {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15),
        0px 0px 8px rgba(0, 0, 0, 0.15);
    }
    .subject-icon {
      color: $gray-700;
    }
  }
}
.subject-title {
  @extend .text-primary-500;
  width: 100%;
  font-size: 1rem !important;
}
.subject-icon {
  color: $gray-500;
  width: 17px;
}

.popular-subject-container {
  padding-top: 80px;
  padding-bottom: 80px;
  .popular-title-wrapper {
    justify-content: center;
  }
  .popular-title {
    gap:8px;
    margin-bottom: 48px;
    direction: initial;
  }
}
@media (max-width: 1453px) {
  .subject-container {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
}
@media (max-width: 1024px) {
  .popular-subject-container {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .subject-container {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
}
@media (max-width: 768px) {
  .popular-subject-container {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    .popular-title {
      margin-bottom: 24px !important;
    }
    .popular-title-wrapper {
      justify-content: unset;
    }
  }
  .subject-container {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}

@media (max-width: 600px) {
  .subject-container {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
  .popular-subject-container {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
